import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
// persist reduxt
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// reducer
import loading from './reducers/loading'
import alert from './reducers/alert'

/*
import cms from './reducers/cms'
import material from './reducers/material'
import product from './reducers/product'
import userlist from './reducers/userlist'
import accessRole from './reducers/accessRole'
import applicationForm from './reducers/applicationForm'
import master from './reducers/master'
import resetpassword from './reducers/resetpassword'
import department from './reducers/department'
import scopeOfWork from './reducers/scopeOfWork'
import jobPosition from './reducers/jobPosition'
import taskType from './reducers/taskType'
import summaryTask from './reducers/summaryTask'
import employee from './reducers/employee'
import company from './reducers/company'
import taskEmployee from './reducers/taskEmployee'
import projectTask from './reducers/projectTask'
import organizationChart from './reducers/organizationChart'
import dailysummary from './reducers/dailysummary'
import notifications from './reducers/notifications'
import projectDashboard from './reducers/projectDashboard'
import employeeDashboard from './reducers/employeeDashboard'
import sharedService from './reducers/sharedService'
import shareServiceEmployee from './reducers/shareServiceEmployee'
import document from './reducers/document'
import employeeActivity from './reducers/employeeActivity'
import documentCategory from './reducers/documentCategory'
import documentTemplate from './reducers/documentTemplate'
import employeeProfile from './reducers/employeeProfile'
import ofmDashboard from './reducers/ofmDashboard'
import OrganizationTeam from './reducers/OrganizationTeam'
import kpiManagement from './reducers/kpiManagement'
import operationProcess from './reducers/operationProcess'
*/
// include reducer here
const rootReducer = combineReducers({
  loading,
  alert
})

const persistConfig = {
  key: 'root',
  storage,
  // set store reducer that you want to persist here
  whitelist: ['sharedService', 'documentTemplate'],
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancers =
  (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
const enhancer = composeEnhancers(applyMiddleware(thunk))

const store = createStore(persistedReducer, enhancer)
const persistor = persistStore(store)

export { store, persistor }
