import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate,useParams } from 'react-router-dom'

import styled from 'styled-components'

import { Apiverify_3rdToken } from '../redux/actions/admin'
import { BASE_URL_3RD } from '../configs/app'
import { ApiMemberApproveConsent } from '../redux/actions/member'

import Button from '../components/form/button/Button'
import ButtonOutline from '../components/form/button/ButtonOutline'
import Modal from '../components/common/Modal'
import CpdConsent from '../pages/CpdConsent'
import OutlineCheckbox from '../components/form/OutlineCheckbox'

import { IoMdClose } from 'react-icons/io'

const Div = styled.div`
  padding: 96px;

  .mr-12-px {
    margin-right: 0.75rem;
  }

  .highlight-error {
    color: var(--Primary-700);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0.75rem;
  }
  .error-headline {
    color: var(--Gray-900);
    font-size: 3.75rem;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }
  .error-desc {
    color: var(--Gray-600);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 3rem;
  }
  .button-container {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
  }
`

const DivModalConsent = styled.div`
  padding: 32px 32px;
  /*min-width: 500px;*/
  /*width: 1073px;*/
  height: ${(props) => props.height || 'auto'};
  overflow-y: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color : #475467;
  .icon {
    cursor: pointer;
  }

  .header_confirm{

  }
  .justify-content-center {
    display: flex;
    justify-content: center;
  }
  .justify-content-end {
    display: flex;
    justify-content: flex-end;
  }
  .justify-content-space-between {
    display: flex;
    justify-content: space-between;
  }
  .modal_text_bold{
    color : #101828;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .modal_text_normal{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .modal_text_input{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .css_magin_top_22{
    margin-top: 22px;
  }
  .css_magin_top_23{
    margin-top: 23px;
  }
  .css_magin_top_32{
    margin-top: 32px;
  }
  .content_confirm{
    display: grid;
    grid-template-columns: repeat(2, 40% [col-start] 60% [col-end]);
    gap: 10px;
    align-items: start;
    margin: 8px 0px;
  }
  .css_margin_0{
    margin:0px
  }
  .icon-check{
    color : var(--Base-Font-Focus-Color);
  }
  .content-topic{
    display: flex;
    /*
    justify-content: center;
    */
    align-items: center;
    line-height: 0px;
    gap: 10px;
  }
  .modal_text_topic_name{
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .box_answer {
    display: flex;
    gap: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color : #079455;
    .box_answer_bold{
      color : #101828;
    }


  }
  .dialog-button {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }

  }
  .text_point_cpd{
    color : #A91F23;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }
  .d-flex {
    display: flex;
    gap: 10px;
  }
`

function LoginToken() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { token } = useParams()
  const [PathMenu, setPathMenu] = useState(null)
  const [ModalConsent, setModalConsent] = useState(false)
  const [isConsent, setIsConsent] = useState(false)

  const [WidthSidePanel, setWidthSidePanel] = useState(window.innerWidth * 0.75)
  const [heightSidePanel, setheightSidePanel] = useState(window.innerHeight * 0.75)

  useEffect(() => {
    dispatch(Apiverify_3rdToken(token)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            if(data_info.accessToken){
                localStorage.setItem('accessToken', data_info.accessToken);
                localStorage.setItem('user_info', JSON.stringify(data_info.member_coe ));
                setPathMenu(data_info.path_menu);
                if(data_info.confirm_consent_at){
                  window.location.href = data_info.path_menu;
                }else{
                  setModalConsent(true);
                }
                
            }else{
                window.location.href = data_info.url_redirect ? data_info.url_redirect : BASE_URL_3RD;
            }
        }else{
            window.location.href = BASE_URL_3RD;
        }
    })
  }, [dispatch, token])

  const gotoHome = () => {
    navigate('/', { replace: true })
  }

  const gotoPrevious = () => {
    navigate(-1, { replace: true })
  }

  const closeModalConsent = () => {
    setModalConsent(false);
  }
  const onProcessAPI = () => {
    const sent_data_to_api = {};
    dispatch(ApiMemberApproveConsent(sent_data_to_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        window.location.href = PathMenu;
      }
    })
  }
  return (
    <Div>
      <div className="highlight-error">ขณะนี้กำลังทำรายการ</div>
      <div className="error-headline">กรุณารอสักครู่</div>
      <div className="error-desc">กรุณาอย่าปิด browser จนกว่าจะทำรายการสำเร็จ</div>


      {/* ModalPoint */}
      <Modal open={ModalConsent} width={WidthSidePanel + 'px'} height={heightSidePanel + 'px'} disableBackdropClick>
          <DivModalConsent height={(heightSidePanel + 15) + 'px'}>
            <div className="header_confirm">
              <div className="justify-content-end">
                <IoMdClose size={20} className="icon" onClick={closeModalConsent} />
              </div>
            </div>
            <div>
              <CpdConsent height={(heightSidePanel - 300) + 'px'} />
            </div>
            <div className="css_magin_top_32"></div>
            <div className="d-flex">
              <div>
                  <OutlineCheckbox
                      className="mr-8-px"
                      checked={isConsent}
                      onChange={(event) => setIsConsent(event.target.checked)}
                  />
              </div>
              <div>
                  <div>ข้าพเจ้ายืนยันว่าได้อ่านและเข้าใจข้อมูลทั้งหมดที่ได้รับแล้ว</div>                          
              </div>
            </div>
            <div className="css_magin_top_32"></div>
            <div className="justify-content-center">
              <Button disabled={!isConsent} onClick={onProcessAPI}>ฉันได้อ่านและรับทราบแล้ว</Button>
            </div>
          </DivModalConsent>
      </Modal>
    </Div>
  )
}

export default LoginToken
