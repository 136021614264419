import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {  getAdminUser } from '../../utils/common'
import { ApiGET_EVENT_ENGINEER,ApiGet_vw_activity_type,ApiGet_vw_field_activity,ApiGet_vw_criteria_activity,ApiGetCpdActivityCriteria } from '../../redux/actions/master'
import { ApiMemberEvents } from '../../redux/actions/events'

import Button from '../../components/form/button/Button'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import InputTextArea from '../../components/widget/element/InputTextArea'
import Date from '../../components/form/Date'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'
import OutlineCheckbox from '../../components/form/OutlineCheckbox'
import UploadFile from '../../components/upload/UploadFile'


import iconPlus from '../../assets/images/icon-plus.svg'
import { FaBullseye } from 'react-icons/fa'
import { GrStatusGoodSmall } from 'react-icons/gr'
import iconDelete from '../../assets/images/icon-bin-red.svg'
import { FaPlus } from "react-icons/fa6";
import { RiDeleteBinLine } from "react-icons/ri";
import warningIcon from '../../assets/images/warning-icon.png'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'
import Fileattachment from '../../assets/images/file-attachment-02.png'

const Div = styled.div`
   margin-bottom: 50px;
   padding: 10px;
  .box_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
  }

  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .dialog-footer {
    text-align: end;
    right: 0px;
    bottom: 70px;
    background-color : #FFFFFF;
    position: absolute;
    width: 100%;
    padding: 0px 45px;
    .button_margin {
      margin: 10px;
    }
  }

`

const DivInfo = styled.div`
  color: var(--Gray-600);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  .main_box {
    display: grid;
    grid-template-columns: repeat(2, 25% [col-start] 75% [col-end]);
    /*gap: 20px;*/
    padding-top: 10px;
    .main_box_text_head {
      font-size: 14px;
      font-weight: 600;
      color: var(--Gray-700);
    }
    .main_box_text_sub {
      font-size: 14px;
      color: var(--Gray-600);
    }
    .main_box_edit {
      border: 1px solid rgb(208, 213, 221);
      border-radius: 8px;
    }
    .main_box_fill_data {
      margin: 16px 24px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      gap: 20px;
      align-items: end;
      .topic_fill {
        font-size: 14px;
        color: #212121;
        margin-bottom: 5px;
      }
      .topic_detail {
        font-size: 14px;
        color: #757575;
      }
    }
    .main_box_button {
      margin: 16px 24px;
      text-align: end;
    }
    .border_buttom {
      border-bottom: 1px solid rgb(234, 236, 240);
    }
  }
  .TextField_employee {
    width: 100%;
  }
  .button_Edit {
    color: #6941c6;
    background-color: #f9f5ff;
    border: 1px solid #eaecf0;
  }
  .button_Cancel {
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #eaecf0;
    margin-right: 1rem;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
  }
  .prefix_icon {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  .input-new-2 {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr repeat(1, auto);
    grid-template-rows: auto;
    align-content: center;
  }

  .ql-container {
    min-height: 250px;
    font-family: inherit;
  }
  .md-right-1rem{
    margin-right: 1rem;
  }
  .box_org_status {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .group_filter {
      display: flex;
      /*align-items: center;*/
    }
  }
  .css_filter_name {
    width: 150px;
    margin-right: 10px;
  }
  .css_button_filter{
    display: inline-block;
    align-self: flex-end;
  }
  .status-badge {
    /*width: 150px;*/
    /*padding: 0.2rem;*/
    width: fit-content;
    padding: 2px 6px;
    text-align: center;
    font-weight: 500;
    border-radius: 16px;
    color: #475467;
    background: #f5f5f4;
    border: 1px #e7e5e4 solid;

    &.expire {
      color:#B42318;
      background: #FEF3F2;
      border: 1px #FECDCA solid;
    }
    &.active {
      color:#067647;
      background: #ECFDF3;
      border: 1px #ABEFC6 solid;
    }

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color:#475467;
      

      &.expire {
        color:#B42318;
      }
      &.active {
        color:#067647;
      }

    }
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
  .text_notice{
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color : #A91F23;
    cursor: pointer;
    :hover{
      text-decoration: underline;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: var(--Shadow-xs);

    tr {
      /*border: 1px solid var(--Gray-200);*/
      border-top: 1px solid var(--Gray-200);
      border-bottom: 1px solid var(--Gray-200);
    }

    th,
    td {
      padding: 16px 14px;
      text-align: left;
      font-size: 14px;
      color: var(--Table-Font-Default);
      white-space: nowrap;
      background: var(--Table-Bg-Default);
    }

    th {
      padding: 12x 14px;
      font-weight: 500;
      background: var(--Gray-50);
    }

    tr:hover {
      td {
        background: var(--Table-Bg-Hover);
        color: var(--Table-Font-Hover);
      }
    }
  }
  .margin-top-space{
    margin-top : 14px;
  }
  .cursor_pointer{
    cursor: pointer;
  }
  .box_attachfile{
    display: flex;
    align-items: start;
    gap:12px;
  }
  .css_attachfile {
    width: fit-content;
    padding: 2px 6px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: #344054;
    background: #FFFFFF;

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color:#067647;
    }
  }
`

const DivErrorMessage = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`



const TabSelfDeclarationForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = getAdminUser()
  const [isConsent, setIsConsent] = useState(false)
  const [engineering_field_option, setengineering_field_option] = useState([])
  useEffect(() => {
    const filter = {
      skip: 0,
      limit: 1000,
    }
    dispatch(ApiGET_EVENT_ENGINEER(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const newStatusOption=  data_info?.data?.map((v) => ({
              text: v?.name_th_th,
              value: v?.id
            }))
            setengineering_field_option([...newStatusOption])
        }
    })
  }, [dispatch])

  

  const [cpd_event_type_option, setcpd_event_type_option] = useState([])
  useEffect(() => {
    const filter = {
      skip: 0,
      limit: 1000,
      show_member : 1
    }
    dispatch(ApiGet_vw_activity_type(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const newStatusOption=  data_info?.data?.map((v) => ({
              text: v?.activity_type,
              value: v?.activity_type_no
            }))
            setcpd_event_type_option([...newStatusOption])
        }
    })
  }, [dispatch])

  const [event_field_option, setevent_field_option] = useState([])
  const fetchDataevent_field_option = useCallback(
    async (filter) => {
      dispatch(ApiGet_vw_field_activity(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const newsetdistrict_option= data_info?.data?.map((v) => ({
                text: v?.activity_details,
                value: v?.activity_number,
            }))
            setevent_field_option([...newsetdistrict_option]);    
        }
      })
    },
    [dispatch]
  )

  const [event_field_lv4_option, setevent_field_lv4_option] = useState([])
  const fetchDataevent_field_lv4_option = useCallback(
    async (filter) => {
      dispatch(ApiGet_vw_criteria_activity(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const newsetdistrict_option= data_info?.data?.map((v) => ({
                text: v?.activity_criteria,
                value: v?.id,
            }))
            setevent_field_lv4_option([...newsetdistrict_option]);    
        }
      })
    },
    [dispatch]
  )

  const initDataInfo = {
    name : '',
    engineering_field : null,
    name_organization : '',
    description : '',
    cpd_event_type : null,
    event_field : null,
    event_field_lv4 : null,
    event_date_list : [{
      date_event : null,
      date_event_error: true,
      qualified_amount : '',
      qualified_amount_error: true,
    }],
    list_file : [],
  };
  const [DataInfo, setDataInfo] = useState(initDataInfo);
  const [DataInfoError, setDataInfoError] = useState({
    name: {
      show_errorMessage: false,
      errorMessage: 'ระบุชื่อกิจกรรม',
    },
    engineering_field: {
      show_errorMessage: false,
      errorMessage: 'ระบุสาขาทางวิศวกรรม',
    },
    name_organization: {
      show_errorMessage: false,
      errorMessage: 'ระบุหน่วยงานที่จัดกิจกรรม',
    },
    cpd_event_type: {
      show_errorMessage: false,
      errorMessage: 'ระบุประเภทกิจกรรม CPD',
    },
    event_field: {
      show_errorMessage: false,
      errorMessage: 'ระบุสาขากิจกรรม CPD',
    },
    event_field_lv4: {
      show_errorMessage: false,
      errorMessage: 'ระบุหลักเกณฑ์การนับคะแนน',
    },
  })

  const initDataTable = {
    activity_count_by : '-',
    cpd_multiplier : '-',
    weight : '-',
    activity_criteria : '-',
    max_point_auto : '-',
  };
  const [TableInfo, setTableInfo] = useState(initDataTable)

  const oncpd_event_type = (v) => {
    if(v){
        const filter = {
          skip: 0,
          limit: 100,
          show_member : 1,
          activity_type_no : v
        }
        fetchDataevent_field_option(filter)
        setDataInfo({ ...DataInfo, cpd_event_type: v ,event_field : null, event_field_lv4 : null })
        setevent_field_lv4_option([])
        setTableInfo(initDataTable)
    }
  }

  const onevent_field = (v) => {
    if(v){
        const filter = {
          skip: 0,
          limit: 100,
          show_member : 1,
          activity_number : v
        }
        fetchDataevent_field_lv4_option(filter)
        setDataInfo({ ...DataInfo, event_field : v, event_field_lv4 : null })
        setTableInfo(initDataTable)
    }
  }

  const onevent_field_lv4 = (v) => {
    if(v){
        setDataInfo({ ...DataInfo, event_field_lv4 : v })
        dispatch(ApiGetCpdActivityCriteria(v)).then(({ type,data_info }) => {
          if(type.endsWith('_SUCCESS')){
            if(data_info.id){
              setTableInfo({
                activity_count_by : data_info.activity_count_by,
                cpd_multiplier : data_info.cpd_multiplier,
                weight : data_info.weight,
                activity_criteria : data_info.activity_criteria,
                max_point_auto : data_info.max_point_auto,
              });
            }
          }
        })
    }
  }

  function handleAddActivityData(currentData) {
    let newDataElement = {
      date_event : null,
      date_event_error: true,
      qualified_amount : null,
      qualified_amount_error: true,
    }
    const newStateData = [...currentData, newDataElement]
    setDataInfo({ ...DataInfo, event_date_list : newStateData })
  }

  const onChangeEvent_date_list = (index_item, e, type_data) => {
    const newList = [...DataInfo.event_date_list];
    if(type_data == 'date_event'){
      newList[index_item].date_event = e;
      newList[index_item].date_event_error = false;
    }else if(type_data == 'qualified_amount'){
      newList[index_item].qualified_amount = e.target.value;
      newList[index_item].qualified_amount_error =  e.target.value ? false : true;
    }
    setDataInfo({ ...DataInfo, event_date_list : newList })
  }

  const DelData = (index_item) => {
    const currentListCopy = [...DataInfo.event_date_list]
    currentListCopy.splice(index_item, 1)
    setDataInfo({ ...DataInfo, event_date_list : currentListCopy })
  }

  const onConfirm = () => {
    if (isValid().length == 0) {
      const ObjEventDateError = DataInfo?.event_date_list.filter((v) => v.date_event_error == true || v.qualified_amount_error == true);
      if (ObjEventDateError.length == 0 ) {
        setConfirmMessageModal({
          headline: 'ยืนยันการทำรายการบันทึกคะแนน CPD ด้วยตนเอง',
          subtitle: 'ชื่อกิจกรรม : ' + DataInfo?.name,
          description: '',
        })
        setModalConfirm(true);
      }
    }
  }

  const isValid = () => {
    let data_valid = DataInfo
    let data_check_valid = DataInfoError
    let errors = []
    Object.keys(DataInfoError).forEach((k) => {
      let data = data_valid[k]
      if (data_check_valid[k].errorMessage != '') {
        if (data === null || data.length === 0 || data === "") {
          errors.push(data_check_valid[k].errorMessage)
          data_check_valid[k].show_errorMessage = true
        } else {
          data_check_valid[k].show_errorMessage = false
        }
      }
    })
    setDataInfoError({ ...DataInfoError })
    return errors
  }

  const initMessageModal = {
      headline: '',
      subtitle: '',
      description: '',
  }
  const [ModalConfirm, setModalConfirm] = useState(false)
  const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)
  const [successModal, setSuccessModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failModal, setFailModal] = useState(false)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  const onProcessAPI = () => {
    const data_to_api = DataInfo;
    dispatch(ApiMemberEvents(data_to_api)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if(data_info.received_point){
            
            setSuccessMessageModal({
                headline: 'บันทึกรายการสำเร็จ',
                subtitle: 'ชื่อกิจกรรม : ' + data_info.name,
                description: 'ท่านได้รับคะแนน CPD ' + data_info.received_point + ' คะแนน',
            })
            setSuccessModal(true);
          }else{
            setFailMessageModal({
              headline: 'ขออภัยไม่สามารถ บันทึกรายการ ได้',
              description: '',
            })
            setFailModal(true)
          }
        }else{
          setFailMessageModal({
            headline: 'internal server error',
            description: '',
          })
          setFailModal(true)
        }
    })
  }

  const onSuccess = () => {
    //setDataInfo(initDataInfo);
    //setTableInfo(initDataTable);
    //setFileInfoList([]);
    //navigate('/Activity/SelfDeclaration')
    window.location.href = '/Activity/SelfDeclaration'
  }

  const [FileInfoList, setFileInfoList] = useState([])
  const limitFileSize = 100; //--- 100MB
  const multiple = true;
  const error = false;
  const disabled = false;
  const allowedTypes = [];
  const acceptType = allowedTypes.reduce((pre, cur) => {
    return {
      ...pre,
      ...acceptList[cur],
    }
  }, {})
  const handleAddFile = async (ObjData) => {
    console.log(ObjData);
    let newDataElement = {
      key_file_id : ObjData.key_file_id,
      file_name : ObjData.original_filename,
      file_url : ObjData.url_file,
      file_size : ObjData.file_size,
    }
    const newStateData = [...DataInfo.list_file, newDataElement]
    setDataInfo({ ...DataInfo, list_file : newStateData })
  }
  const handleDeleteFile = (key_file_id) => {
    setFileInfoList(FileInfoList.filter((v) => v.key_file_id !== key_file_id));
    const newDataElement = DataInfo.list_file.filter((v) => v.key_file_id !== key_file_id)
    setDataInfo({ ...DataInfo, list_file : newDataElement })
  }

  return (
    <Div>
      <DivInfo>
        <div className="main_box">
          <div>
            <div className="main_box_text_head">ข้อมูลเกี่ยวกับกิจกรรม</div>
            <div className="main_box_text_sub">ข้อมูลทั่วไปเกี่ยวกับกิจกรรม</div>
          </div>
          <div>
            <div className="main_box_edit">
              <div className="main_box_fill_data">
                <div>
                  <div className="topic_fill">ชื่อกิจกรรม*</div>
                  <div>
                    <TextField
                      name="name"
                      className="TextField_employee"
                      value={DataInfo.name}
                      onChange={(e) => setDataInfo({ ...DataInfo, name: e.target.value })}
                      placeholder={'โปรดระบุ'}
                      error={Boolean(DataInfoError.name.show_errorMessage)}
                    />
                    {DataInfoError.name.show_errorMessage == true && (
                        <DivErrorMessage>{DataInfoError.name.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
              </div>
              <div className="main_box_fill_data">
                <div>
                  <div className="topic_fill">สาขาทางวิศวกรรม*</div>
                  <div>
                    <Dropdown
                        id={`dd_org_type`}
                        value={DataInfo.engineering_field}
                        optionList={engineering_field_option}
                        onChange={(v) => setDataInfo({ ...DataInfo, engineering_field: v })}
                        placeHolder={'กรุณาเลือก'}
                        ValueplaceHolderIsNull={true}
                        error={Boolean(DataInfoError.engineering_field.show_errorMessage)}
                    />
                    {DataInfoError.engineering_field.show_errorMessage == true && (
                        <DivErrorMessage>{DataInfoError.engineering_field.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">หน่วยงานที่จัดกิจกรรม*</div>
                  <div>
                    <TextField
                      name="name"
                      className="TextField_employee"
                      value={DataInfo.name_organization}
                      onChange={(e) => setDataInfo({ ...DataInfo, name_organization: e.target.value })}
                      placeholder={'โปรดระบุ'}
                      error={Boolean(DataInfoError.name_organization.show_errorMessage)}
                    />
                    {DataInfoError.name_organization.show_errorMessage == true && (
                        <DivErrorMessage>{DataInfoError.name_organization.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
              </div>
              <div className="main_box_fill_data">
                <div>
                  <div className="topic_fill">รายละเอียดกิจกรรม</div>
                  <div>
                       <InputTextArea
                          className="InputTextArea_css"
                          initValue={DataInfo.description}
                          onChange={(e) => setDataInfo({ ...DataInfo, description: e.target.value  })}
                          placeHolder="กรุณาระบุ..."
                          borderColor={'var(--Gray-300)'}
                          borderWidth="1px"
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border_header"></div>
        <div className="main_box">
          <div>
            <div className="main_box_text_head">ข้อมูลเกี่ยวกับประเภทกิจกรรม CPD</div>
            <div className="main_box_text_sub">ระบุประเภทกิจกรรม CPD</div>
          </div>
          <div>
            <div className="main_box_edit">
              <div className="main_box_fill_data">
                <div>
                  <div className="topic_fill">ประเภทกิจกรรม CPD*</div>
                  <div>
                    <Dropdown
                        id={`dd_cpd_event_type`}
                        value={DataInfo.cpd_event_type}
                        optionList={cpd_event_type_option}
                        onChange={oncpd_event_type}
                        placeHolder={'กรุณาเลือก'}
                        ValueplaceHolderIsNull={true}
                        error={Boolean(DataInfoError.cpd_event_type.show_errorMessage)}
                    />
                    {DataInfoError.cpd_event_type.show_errorMessage == true && (
                        <DivErrorMessage>{DataInfoError.cpd_event_type.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
                <div className="text_notice">
                  อ่านรายละเอียดประเภทกิจกรรม CPD
                </div>
              </div>
              <div className="main_box_fill_data">
                <div>
                  <div className="topic_fill">สาขากิจกรรม CPD*</div>
                  <div>
                    <Dropdown
                        id={`dd_org_type`}
                        value={DataInfo.event_field}
                        optionList={event_field_option}
                        onChange={onevent_field}
                        placeHolder={'กรุณาเลือก'}
                        ValueplaceHolderIsNull={true}
                        error={Boolean(DataInfoError.event_field.show_errorMessage)}
                    />
                    {DataInfoError.event_field.show_errorMessage == true && (
                        <DivErrorMessage>{DataInfoError.event_field.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                  <div>(กิจกรรมที่จัดโดยองค์กรแม่ข่าย กรุณาเข้าร่วมกิจกรรม และ Scan QR เพื่อรับคะแนน)</div>
                </div>
              </div>
              <div className="main_box_fill_data">
                <div>
                  <div className="topic_fill">หลักเกณฑ์การนับคะแนน*</div>
                  <div>
                    <Dropdown
                        id={`dd_org_type`}
                        value={DataInfo.event_field_lv4}
                        optionList={event_field_lv4_option}
                        onChange={onevent_field_lv4}
                        placeHolder={'กรุณาเลือก'}
                        ValueplaceHolderIsNull={true}
                        error={Boolean(DataInfoError.event_field_lv4.show_errorMessage)}
                    />
                    {DataInfoError.event_field_lv4.show_errorMessage == true && (
                        <DivErrorMessage>{DataInfoError.event_field_lv4.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
              </div>
              <div className="main_box_fill_data">
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>เกณฑ์การนับ (นับตาม)</th>
                        <th>ตัวคูณ CPD</th>
                        <th>ตัวคูณน้ำหนัก</th>
                        <th>วิธีการคำนวณคะแนนแบบCPD</th>
                        <th>คะแนนสูงสุดที่จะได้รับ</th>
                      </tr>
                    </thead>  
                    <tbody>
                      <tr>
                        <td>{ TableInfo?.activity_count_by }</td>
                        <td>{ TableInfo?.cpd_multiplier }</td>
                        <td>{ TableInfo?.weight }</td>
                        <td>{ TableInfo?.activity_criteria }</td>
                        <td>{ TableInfo?.max_point_auto }</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border_header"></div>
        <div className="main_box">
          <div>
            <div className="main_box_text_head">รายงานจำนวนที่นับตามเกณฑ์</div>
            <div className="main_box_text_sub">วันที่ จำนวนชั่วโมง หรือตามเกณฑ์อื่นๆ แนบเอกสารประกอบ</div>
          </div>
          <div>
            <div className="main_box_edit">
              <div className="main_box_fill_data">
                <div>
                  <div>
                    <table>
                      <thead>
                        <tr>
                          <th>ลำดับ</th>
                          <th>วันที่เข้าร่วมกิจกรรม</th>
                          <th>เกณฑ์การนับคะแนน</th>
                          <th>กรุณาระบุจำนวนตามเกณฑ์</th>
                          <th></th>
                        </tr>
                      </thead>  
                      <tbody>
                        {DataInfo?.event_date_list.map((item, index) => (
                          <tr>
                            <td>{ index + 1 }</td>
                            <td>
                              <Date
                                  className={'input-calendar'}
                                  value={item.date_event}
                                  onChange={(v) => onChangeEvent_date_list(index, v, 'date_event')}
                                  error={Boolean(item.date_event_error)}
                              />
                              {item.date_event_error == true && (
                                  <DivErrorMessage>{'ระบุวันที่เข้าร่วมกิจกรรม'}</DivErrorMessage>
                              )}
                            </td>
                            <td>คำนวณตามประเภท CPD</td>
                            <td>
                              <TextField
                                  placeholder="ระบุ"
                                  value={item.qualified_amount}
                                  onChange={(v) => onChangeEvent_date_list(index, v, 'qualified_amount')}
                                  error={Boolean(item.qualified_amount_error)}
                              />
                              {item.qualified_amount_error == true && (
                                  <DivErrorMessage>{'กรุณาระบุจำนวนตามเกณฑ์'}</DivErrorMessage>
                              )}
                            </td>
                            <td>
                              <RiDeleteBinLine size={20} className="cursor_pointer" onClick={() => DelData(index)} />
                              
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="margin-top-space">
                    <ButtonSecondary append={<FaPlus />} onClick={() => handleAddActivityData(DataInfo.event_date_list)} >
                        เพิ่มรายการ
                    </ButtonSecondary>
                  </div>
                </div>
              </div>
              <div className="main_box_fill_data">
                <div className="box_attachfile">
                  <div>
                    <img src={Fileattachment} />    
                  </div>
                  <div >
                    <div className="topic_fill">
                      แนบเอกสารประกอบกิจกรรม*
                    </div>
                    <div className="css_attachfile">
                      <GrStatusGoodSmall className="status" />
                      รายการเอกสารที่ต้องแนบ กำหนดตามประเภทกิจกรรม CPD ที่ท่านเลือก
                    </div>
                    <div className="css_attachfile">
                      (กรุณาแนบเอกสารเป็นไฟล์นามสกุล .pdf หรือ .jpg หรือ .jpeg หรือ .png)
                    </div>
                  </div>
                </div>
              </div>
              <div className="main_box_fill_data">
                <div>
                  <UploadFile
                      multiple={multiple}
                      accept={acceptType}
                      file={FileInfoList.map((v) => ({ ...v, url: `${v.url}` }))}
                      onAddFile={handleAddFile}
                      onDeleteFile={handleDeleteFile}
                      error={error}
                      disabled={disabled}
                      FileListData={FileInfoList}
                      setFileListData={setFileInfoList}
                      isOneFile={false}
                      limitFileSize={limitFileSize}
                      subtext={'PDF, JPG, JPEG, PNG (max. 10MB)'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border_header"></div>
      </DivInfo>
      {/* Button */}
      <div className="dialog-footer">
          <div className="border_header"></div>
          <div className="button-footer">
              <div>
                  <Button className="button_margin" onClick={onConfirm}>
                      {'บันทึกกิจกรรมด้วยตนเอง'}
                  </Button>
              </div>
          </div>
      </div>

      <DialogConfirmV2
        open={Boolean(ModalConfirm)}
        onClose={() => setModalConfirm(false)}
        onNo={() => setModalConfirm(false)}
        onSubmit={() => {
          setModalConfirm(false)
          onProcessAPI()
        }}
        icon={successIcon}
        title={ConfirmMessageModal.headline}
        nameItem={ConfirmMessageModal.subtitle}
        html={
          <div className="d-flex-consent">
            <div>
                <OutlineCheckbox
                    className="mr-8-px"
                    checked={isConsent}
                    onChange={(event) => setIsConsent(event.target.checked)}
                />
            </div>
            <div className="description">
                <div>ข้าพเจ้าขอรับรองว่าข้อมูลที่แสดงไว้ถูกต้องทุกประการ</div>
                <div>หากสภาวิศวกรพบว่ามีข้อมูลหรือเอกสารไม่ถูกต้อง</div>
                <div>ข้าพเจ้ายินยอมให้สภาวิศวกรปฎิเสธการได้รับคะแนนในรายการนี้</div>                          
            </div>
          </div>
        }
        textYes='ยืนยันและบันทึก'
        textNo='ยกเลิก'
        isActiveTextYes={isConsent}
      />

        <DialogSuccess
            open={Boolean(successModal)}
            onClose={() => setSuccessModal(false)}
            onSubmit={() => {
              setSuccessModal(false)
              onSuccess()
            }}
            icon={successIcon}
            title={successMessageModal.headline}
            subtitle={successMessageModal.subtitle}
            description={successMessageModal.description}
            textYes={'ตกลง'}
        />
       <DialogFail
            open={Boolean(failModal)}
            onClose={() => setFailModal(false)}
            onSubmit={() => {
                setFailModal(false)
            }}
            icon={failIcon}
            title={failMessageModal.headline}
            description={failMessageModal.description}
            textYes='ตกลง'
        />
    </Div>
  )
}

export default TabSelfDeclarationForm
