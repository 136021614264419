import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate,useParams } from 'react-router-dom'

import {  getAdminUser,DateDisplayDDMMBBB,DateTimeHHmmDisplay,MonthList,DisplayTableActivityName  } from '../../utils/common'
import { ApiGET_EVENT_ENGINEER } from '../../redux/actions/master'
import { ApiGetVWMemberEventsRegister,ApiUpdateUserActivityPoint } from '../../redux/actions/events'

import Button from '../../components/form/button/Button'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import TableV2 from '../../components/common/TableV2'
import Field from '../../components/form/Field'
import SearchText from '../../components/form/SearchText'
import Dropdown from '../../components/form/Dropdown'
import SidePanel from '../../components/common/SidePanel'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'

import iconPlus from '../../assets/images/icon-plus.svg'
import iconEdit from '../../assets/images/edit-icon.png'
import iconDelete from '../../assets/images/icon-bin-red.svg'
import { FaBullseye } from 'react-icons/fa'
import { GrStatusGoodSmall } from 'react-icons/gr'
import warningIcon from '../../assets/images/warning-icon.png'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'

const Div = styled.div`
.cursor_pointer {
    cursor: pointer;
  }
  .box_header{
    display: flex;
    justify-content: space-between;
  }
  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
  }
  .content-title-text {
    color: var(--Base-Font-Title-SUB-Color);
    font-size: 18px;
  }
  .css_button_add{
    display: inline-block;
    align-self: flex-end;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
  }
  .status-badge {
    width: fit-content;
    padding: 2px 6px;
    text-align: center;
    font-weight: 500;
    border-radius: 16px;
    color: #475467;
    background: #f5f5f4;
    border: 1px #e7e5e4 solid;

    &.expire {
      color:#B42318;
      background: #FEF3F2;
      border: 1px #FECDCA solid;
    }
    &.active {
      color:#067647;
      background: #ECFDF3;
      border: 1px #ABEFC6 solid;
    }

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: #475467;
      

      &.expire {
        color:#B42318;
      }
      &.active {
        color:#067647;
      }

    }
  }

  .table-wrapper {
    margin-top: 24px;
    border: 1px solid #eaecf0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #ffffff;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Table-Bg-Header);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--BADGE-Table-Font);
        background: var(--BADGE-Table-Bg);
        border-radius: 16px;
        border: 1px var(--BADGE-Table-Border) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
  .css_magin_top_10 {
    margin-top: 10px;
  }
  .css_magin_top_24 {
    margin-top: 24px;
  }
  .button_view_data {
    color: #A91F23;
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
  }
  .button_cancle_data {
    color: #182230;
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
  }
  .text-bold {
    font-weight: bold;
  }
  
  .link {
    color: var(--Td-Font-Color-Link);
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`


const DivFilter = styled.div`
min-height: 68px;
background-color: var(--Gray-50);
border-radius: 8px;
margin-bottom: 21px;
padding: 10px;
.content-fillter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .search-wrapper {
        margin-right: 12px;
    }

    .filter-list {
        flex-basis: 100%;
        margin-top: 24px;
    }

    .group_filter {
        display: flex;
        align-items: center;
        gap:10px;
    }
}
.css_filter_name {
    min-width: 380px;
    /*margin-right: 10px;*/
}
.mr-1-rem {
    margin-right: 1rem;
}
.css_button_filter{
    display: inline-block;
    align-self: flex-end;
}
.dd_filter{
    min-width: 380px;
}
.dd_filterStatusExam{
  min-width: 115px;
}
`



const TabRegisterEvents = () => {
  const dispatch = useDispatch()
  const user = getAdminUser()
  const navigate = useNavigate()

  const [Filter, setFilter] = useState({
    name : null,
    month : '',
    year : ''
  })
  const [MonthOption, setMonthOption] = useState(MonthList)
  const [YearOption, setYearOption] = useState([
    {
        value : 2022,
        text : "2565",
    },
    {
        value : 2023,
        text : "2566",
    },
    {
        value : 2024,
        text : "2567",
    },
  ])


  const limit_data = 10;
  const [total, settotal] = useState(0)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [TableCellData, setTableCellData] = useState([])
  const TableColumnData = [
    {
      Header: 'ลำดับ',
      accessor: 'no',
      disableSortBy: false,
    },
    {
        Header: 'ชื่อกิจกรรม',
        accessor: 'name',
        disableSortBy: false,
    },
    {
        Header: 'สาขาวิศวกรรม',
        accessor: 'engineering_field_name',
        disableSortBy: false,
    },
    {
        Header: 'องค์กรแม่ข่าย',
        accessor: 'organizations_name',
        disableSortBy: false,
    },
    {
        Header: 'คะแนน PDU',
        accessor: 'point',
        disableSortBy: false,
    },
    {
        Header: 'วันที่จัดกิจกรรม',
        accessor: 'start_at',
        disableSortBy: false,
    },
    {
        Header: 'Status',
        accessor: 'status',
        disableSortBy: false,
    },
    {
        accessor: 'view_data',
        disableSortBy: false,
    },
    {
        accessor: 'cancle_data',
        disableSortBy: false,
    },
  ]

  const fetchData = useCallback(
    async (filter) => {

      dispatch(ApiGetVWMemberEventsRegister(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if (data_info.total > 0) {
            const totalPage = Math.ceil(data_info.total / limit_data)
            settotal(data_info.total)
            setTotalPages(totalPage)
            const ThisSkip = data_info.skip == 0 ? 1 : (data_info.skip / limit_data) + 1;

            const newTableCellData = data_info.data.map((v, index) => ({
              no: limit_data * ThisSkip - (9 - index),
              name: v.name ? (
                <div className="text-bold link" onClick={() => OpenViewModal(v)}>
                  {DisplayTableActivityName(v.name) }
                </div>
              ) : '-',
              engineering_field_name: v.engineering_field_name ? v.engineering_field_name : '-',
              organizations_name: v.organizations_name ? v.organizations_name : '-',
              point: v.point ? v.point : '-',   
              start_at: v.start_at ? DateDisplayDDMMBBB(v.start_at) : '-',      
              status: (
                v.status_name ? 
                <div
                  className="status-badge"
                  style={{
                    borderColor: v.status_badge_color?.border,
                    backgroundColor: v.status_badge_color?.background,
                  }}
                >
                  <GrStatusGoodSmall 
                    className="status"
                    style={{
                      color: v.status_badge_color?.color,
                    }}
                  />
                  {v.status_name}
                </div>
                : 
                <div >-</div>
              ),
              view_data: (
                <ButtonOutline className="button_view_data"  onClick={() => OpenViewModal(v)}>
                  ดูรายละเอียด
                </ButtonOutline>
              ),
              cancle_data: (
                <ButtonSecondary className="button_cancle_data"  onClick={() => CancleData(v)}>
                  ยกเลิก
                </ButtonSecondary>
              )
            }))
            setTableCellData(newTableCellData)
          }else{
            settotal(0);
            setPage(0);
            setTotalPages(0);
            setTableCellData([]);
          }
         
        }
      })

    },
    [dispatch]
  )

  useEffect(() => {
    const filter = {
      skip: 0,
      limit: limit_data,
    }
    fetchData(filter) //-- ปิด รอ API
  }, [fetchData, limit_data])


  const onChangePaginationAttendeeAttend = async (value) => {
    const filter = {
      skip: (value.page  - 1) * limit_data, 
      limit: limit_data,
    }
    fetchData(filter)
    setPage(value.page)
  }

  const onSearch = () => {
    const filter = {
      skip: 0,
      limit: limit_data,
    }
    fetchData(filter)
    setPage(1)
  }
  const onClearSearch = () => {
    const filter = {
      skip: 0,
      limit: limit_data,
    }
    fetchData(filter)
    
    setPage(1)
  }
  const [ThisId, setThisId] = useState(null)
  const [event_participants_id, setevent_participants_id] = useState(null)
  const [WidthSidePanel, setWidthSidePanel] = useState(400);
  const [ModalNew, setModalNew] = useState(false);
  const [ModalEdit, setModalEdit] = useState(false);
  const [ModalView, setModalView] = useState(false);
  const OpenCreateModal = () => {
    setModalNew(true);
  }
  const OpenViewModal = (Obj) => {
    navigate('/Activity/EventsInfo/' + Obj.id)
  }
  const OpenEditModal = (Obj) => {
    setThisId(Obj.id);
    setModalEdit(true);
  }
  const CancleData = (data) => {
    //alert(data.event_participants_id)
    setevent_participants_id(data.event_participants_id)
    setConfirmMessageModal({
        headline: 'ต้องการยกเลิกรายการนี้หรือไม่',
        message: '',
    })
    setModalConfirmDialogDel(true);
  }

  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const [ModalConfirmDialogDel, setModalConfirmDialogDel] = useState(false)
  const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)

  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  const onClearfilterName = () => {
    setFilter({ ...Filter, name: '' })
  }

  const ApiProcess = () => {
    const data_to_api = {
      status_id: 2 ,
    }
    
    dispatch(ApiUpdateUserActivityPoint(event_participants_id,data_to_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        setSuccessMessageModal({
            headline: 'ยกเลิกสำเร็จ',
            message: '',
        })
        setSuccessModal(true);
      }else{
        setFailMessageModal({
          headline: 'internal server error',
          message: '',
        })
        setFailModal(true)
      }
    })
  }
  return (
    <Div>

        <DivFilter>
          <div className="content-fillter">
            <div className="group_filter">
              <div className="css_filter_name">
                <Field className="field" label="Search ค้นหา">
                    <SearchText
                        placeholder="ค้นหารายละเอียด / ชื่อกิจกรรม"
                        value={Filter.name}
                        onChange={(e) => setFilter({ ...Filter, name: e.target.value })}
                        onClear={onClearfilterName}
                    />
                </Field>
              </div>
              <div >
                <Field className="field" label="เดือน">
                    <Dropdown
                        id={`dd_filterUser`}
                        value={Filter.month}
                        optionList={MonthOption}
                        onChange={(v) => setFilter({ ...Filter, month: v })}
                        placeHolder={'เลือก'}
                    />
                </Field>
              </div>
              <div >
                <Field className="field" label="ปี">
                    <Dropdown
                        id={`dd_filterUser`}
                        value={Filter.year}
                        optionList={YearOption}
                        onChange={(v) => setFilter({ ...Filter, year: v })}
                        placeHolder={'เลือก'}
                    />
                </Field>
              </div>
              <div className="css_button_filter">
                  <Button onClick={onSearch} >
                    ค้นหา
                  </Button>
              </div>
              <div className="css_button_filter">
                <ButtonSecondary onClick={onClearSearch} >
                  ล้างการค้นหา
                </ButtonSecondary>
              </div>
            </div>
          </div>
        </DivFilter>
        <div className="table-wrapper">
          <div className="table-header">
            <div className="group">
              <div className="table-title">ตารางกิจกรรม</div>
              <div className="table-total">
                {total} รายการ
              </div>
            </div>
          </div>
          <div className="css_magin_top_10">
            <TableV2
              columns={TableColumnData}
              data={TableCellData}
              onStateChange={onChangePaginationAttendeeAttend}
              pageCount={totalPages}
              page={page}
              loading={false}
            />
          </div>
        </div>
        
        <DialogConfirmV2
        open={Boolean(ModalConfirmDialogDel)}
        onClose={() => setModalConfirmDialogDel(false)}
        onNo={() => setModalConfirmDialogDel(false)}
        onSubmit={() => {
          setModalConfirmDialogDel(false)
          ApiProcess()
        }}
        icon={warningIcon}
        title={ConfirmMessageModal.headline}
        nameItem={ConfirmMessageModal.message}
        textYes='ยืนยัน'
        textNo='ยกเลิก'
      />

      {/* Dialog */}
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={() => {
          setSuccessModal(false)
          onClearSearch()
        }}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes='ตกลง'
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => {
          setFailModal(false)
          onClearSearch()
        }}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes='ตกลง'
      />
    </Div>
    
  )
}

export default TabRegisterEvents
