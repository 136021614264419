const genRequest = (type) => ({
  REQUEST: `${type}_REQUEST`,
  SUCCESS: `${type}_SUCCESS`,
  FAILURE: `${type}_FAILURE`,
})

export const CLEAR_ALERT = 'CLEAR_ALERT'

export const SHOW_LOADING = 'SHOW_LOADING'
export const HIDE_LOADING = 'HIDE_LOADING'

export const SET_IS_REDIRECT = 'SET_IS_REDIRECT'

export const SET_IS_REFRESH_NOTI = 'SET_IS_REFRESH_NOTI'

//----- src\redux\actions\admin.js
export const LOGIN_ADMIN = genRequest('LOGIN_ADMIN')
export const VERIFY_OTP = genRequest('VERIFY_OTP')
export const RESEND_OTP = genRequest('RESEND_OTP')

//----- src\redux\actions\master.js
export const GET_TITLE = genRequest('GET_TITLE')
export const GET_province = genRequest('GET_province')
export const GET_district = genRequest('GET_district')
export const GET_sub_district = genRequest('GET_sub_district')
export const GET_OrgType = genRequest('GET_OrgType')
export const GET_EngineeringCourse = genRequest('GET_EngineeringCourse')
export const GET_STATUS = genRequest('GET_STATUS')
export const GET_vw_cpd_activity_criteria_and_conditions = genRequest('GET_vw_cpd_activity_criteria_and_conditions')
export const GET_vw_activity_type = genRequest('GET_vw_activity_type')
export const GET_elearning_prototype_img = genRequest('GET_elearning_prototype_img')
export const GET_MASTER = genRequest('GET_MASTER')
export const GET_SERVICE_TYPE = genRequest('GET_SERVICE_TYPE')
export const GET_EVENT_ENGINEER = genRequest('GET_EVENT_ENGINEER')
export const GET_vw_field_activity = genRequest('GET_vw_field_activity')
export const GET_vw_criteria_activity = genRequest('GET_vw_criteria_activity')
export const GET_MEMBER_ORG = genRequest('GET_MEMBER_ORG')


//----- src\redux\actions\organizations.js
export const organization_profile = genRequest('organization_profile')
export const GET_organization = genRequest('GET_organization')
export const UPDATE_organization = genRequest('UPDATE_organization')
export const GET_ApiCPDNumNotification = genRequest('GET_ApiCPDNumNotification')

//----- src\redux\actions\elearning.js
export const GET_elearning = genRequest('GET_elearning')
export const POST_elearning = genRequest('POST_elearning')
export const UPDATE_elearning = genRequest('UPDATE_elearning')

//----- src\redux\actions\member.js
export const GET_MEMBER = genRequest('GET_MEMBER')
export const POST_MEMBER = genRequest('POST_MEMBER')
export const UPDATE_MEMBER = genRequest('UPDATE_MEMBER')

//----- src\redux\actions\events.js
export const GET_EVENTS = genRequest('GET_EVENTS')
export const POST_EVENTS = genRequest('POST_EVENTS')
export const UPDATE_EVENTS = genRequest('UPDATE_EVENTS')

//----- src\redux\actions\appeal.js
export const GET_APPEAL = genRequest('GET_APPEAL')
export const POST_APPEAL = genRequest('POST_APPEAL')
export const UPDATE_APPEAL = genRequest('UPDATE_APPEAL')
