import { getAPI, postAPI,patchAPI } from '../../utils/api'
import { 
    GET_EVENTS,
    POST_EVENTS,
    UPDATE_EVENTS
} from '../actionTypes'
import { showLoading, hideLoading } from './loading'

export const ApiGetVWEventsORG = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: GET_EVENTS.REQUEST })
  
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      let params = {
        '$limit': filter.limit,
        '$skip': filter.skip,
      }
      let engineer_filter = filter?.engineer_filter ? '&engineering_field[$in][]=' + filter?.engineer_filter : '';
      console.log(engineer_filter)
      /*
      if(filter?.engineer_filter){
        const arr_data = filter?.engineer_filter.split(",");
        for (const arr_data_item of arr_data) {
          params = {...params, 'engineering_field[$in][]': arr_data_item}
          console.log(params)
        }
      }
      */

      if(filter?.FilterSearch?.name){
        params = {...params,'name[$like]': `%${filter?.FilterSearch?.name}%`}
      }

      if(filter?.FilterSearch?.organizations_name){
        params = {...params,'organizations_name[$like]': `%${filter?.FilterSearch?.organizations_name}%`}
      }

      if(filter?.FilterSearch?.month){
        params = {...params,'month_start_at': filter?.FilterSearch?.month}
      }

      if(filter?.FilterSearch?.year){
        params = {...params,'year_start_at': filter?.FilterSearch?.year}
      }
  
      const data_result = await getAPI({
        url: '/api/events/member_vw_events_org?$sort[id]=-1' + engineer_filter,
        headers: headers,
        params,
      })
  
      return { type: 'ApiGetVWEventsORG_SUCCESS', data_info: data_result }
  
    } catch (err) {
      return { type: 'ApiGetVWEventsORG_ERROR' }
  
    } finally {
      dispatch(hideLoading())
    }
}

export const ApiGetVWMemberEventsRegister = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: GET_EVENTS.REQUEST })
  
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      let params = {
        '$limit': filter.limit,
        '$skip': filter.skip,
      }
  
      const data_result = await getAPI({
        url: '/api/events/vw_member_events_register?$sort[event_participants_id]=-1',
        headers: headers,
        params,
      })
  
      return { type: 'ApiGetVWMemberEventsRegister_SUCCESS', data_info: data_result }
  
    } catch (err) {
      return { type: 'ApiGetVWMemberEventsRegister_ERROR' }
  
    } finally {
      dispatch(hideLoading())
    }
}

export const ApiGetEventInfo = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_EVENTS.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/events/MemberGetEventInfo`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiGetEventInfo_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetEventInfo_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiMemberPreregister = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_EVENTS.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/events/MemberPreregister`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiMemberPreregister_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiMemberPreregister_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiUpdateMemberParticipants = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_EVENTS.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    const data_result = await patchAPI({
      url: `/api/events/MemberParticipants/${id}`,
      data,
      headers: headers,
    })

    return { type: 'ApiUpdateMemberParticipants_SUCCESS', data_info: data_result }

  } catch (err) {

    return { type: 'ApiUpdateMemberParticipants_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiExternalGetEventInfo = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_EVENTS.REQUEST })
    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/events/ExternalGetEventInfo`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiExternalGetEventInfo_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiExternalGetEventInfo_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiExternalCheckIn = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_EVENTS.REQUEST })
    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/events/ExternalCheckIn`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiExternalCheckIn_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiExternalCheckIn_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiExternalPreregister = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_EVENTS.REQUEST })
    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/events/ExternalPreregister`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiExternalPreregister_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiExternalPreregister_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetVWMemberSelfDeclaration = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_EVENTS.REQUEST })

    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }

    const data_result = await getAPI({
      url: '/api/events/vw_member_self_declaration?$sort[id]=-1',
      headers: headers,
      params,
    })

    return { type: 'ApiGetVWMemberSelfDeclaration_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetVWMemberSelfDeclaration_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiMemberEvents = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_EVENTS.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/events/MemberEvents`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiMemberEvents_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiMemberEvents_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiExternalPreregisterGeneral = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_EVENTS.REQUEST })
    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/events/ExternalPreregisterGeneral`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiExternalPreregisterGeneral_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiExternalPreregisterGeneral_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiExternalCheckInGeneral = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_EVENTS.REQUEST })
    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/events/ExternalCheckInGeneral`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiExternalCheckInGeneral_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiExternalCheckInGeneral_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiUpdateUserActivityPoint = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_EVENTS.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    await patchAPI({
      url: `/api/member/MemberUserActivityPoint/${id}`,
      data,
      headers: headers,
    })

    return { type: 'ApiUpdateUserActivityPoint_SUCCESS', data_info: { code : 200 } }

  } catch (err) {

    return { type: 'ApiUpdateUserActivityPoint_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiExternalEventCheckEmail = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_EVENTS.REQUEST })
    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/events/ExternalEventCheckEmail`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiExternalEventCheckEmail_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiExternalEventCheckEmail_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiExternalEventSentEmail = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_EVENTS.REQUEST })
    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/events/ExternalEventSentEmail`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiExternalEventSentEmail_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiExternalEventSentEmail_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiExternalEventApprove = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_EVENTS.REQUEST })
    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/events/ExternalEventApprove`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiExternalEventApprove_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiExternalEventApprove_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}