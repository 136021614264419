import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate,useParams } from 'react-router-dom'
import InputMask from "react-input-mask";

import {  getAdminUser,DateDisplay,DateTimeHHmmDisplay,TimeDisplayHHmm,DateDisplayFullNameMonth  } from '../../utils/common'
import { ApiExternalGetEventInfo,ApiExternalPreregister } from '../../redux/actions/events'

import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import TableV2 from '../../components/common/TableV2'
import Field from '../../components/form/Field'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import TextField from '../../components/form/TextField'

import iconPlus from '../../assets/images/icon-plus.svg'
import logoIcon from '../../assets/images/logo-icon.png'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'

const Div = styled.div`
    width: 100%;
    max-width: 520px;
    margin: 0 auto;
    .css_magin_top_12 {
        margin-top: 12px;
    }
    .css_magin_top_32 {
        margin-top: 32px;
    }
    .img-container {
        padding-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        
        img {
            width: 150px;
            /*height: 48px;*/
        }
    
    }
    .title{
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        color : #101828;
        text-align: center;
    }
    .desc{
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        color : #475467;
        text-align: center;
    }
    .event_name{
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color : #182230;
    }
    .event_date{
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        color : #182230;
    }
    .event_organizations_name{
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color : #182230;
    }
    .button-login {
        display: block;
        width: 100%;
    }
    .box_input_1{
      margin-top: 10px;
    }

    @media screen and (max-width: 440px) {
      max-width: 300px;
    }

`

const DivStyleInputMask = styled.div`
  display: inline-flex;
  background: var(--Base-White);
  border: 1px solid var(--Gray-300);
  box-shadow: var(--Shadow-xs);
  border-radius: 8px;
  height: 40px;
  input {
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    min-width: 0;
    border: none;
    /*font-family: 'Inter';*/
    font-family: inherit;
    font-size: 14px;
    line-height: 1.5;
    color: var(--Gray-500);
  }
`

const DivErrorMessage = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`

const EventPreregister = () => {
  const dispatch = useDispatch()
  const user = getAdminUser()
  const { id } = useParams()
  const navigate = useNavigate()

  //---- กรณีเป็น URL Production เก่าจะสั่ง redirect ไปที่ URL Production ใหม่
  useEffect(() => {
    if(window.location.hostname == 'cpdmember.coe.or.th'){
      window.location.href = 'https://cpdmemberv2.coe.or.th/EventPreregister/' + id
    }
  }, [])


  const initMessageModal = {
    headline: '',
    subtitle: '',
    description: '',
}
const [successModal, setSuccessModal] = useState(false)
const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
const [failModal, setFailModal] = useState(false)
const [failMessageModal, setFailMessageModal] = useState(initMessageModal)
  const [DataInfo, setDataInfo] = useState({
    event_name : '',
    start_at : '',
    organizations_name : '',
    is_open_general_user : false,
  })
  const [UserName, setUserName] = useState('')
  const [RegisterData, setRegisterData] = useState({
    UserName : null,
    user_organization_name: null,
  })
  const [RegisterDataError, setRegisterDataError] = useState({
    UserName: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุเลขบัตรประจำตัวประชาชน 13 หลัก',
    },
    /*
    user_organization_name: {
        show_errorMessage: false,
        errorMessage: 'กรุณาระบุชื่อหน่วยงาน/ที่ทำงาน ของท่าน',
    },
    */
  })
  
  useEffect(() => {
    const sent_data_to_api = {
        event_id : id,
    };
    dispatch(ApiExternalGetEventInfo(sent_data_to_api)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            if(data_info.data_info){
                setDataInfo({
                    event_name : data_info.data_info.name,
                    start_at : data_info.data_info.start_at,
                    organizations_name : data_info.data_info.organizations_name,
                    is_open_general_user : data_info.data_info.is_open_general_user
                })
            }
        }
    })
  }, [dispatch])
  
  

  const ApiProcess = () => {
    //const real_UserName = UserName.replaceAll('-', '');
    if (isValid().length == 0) {
      const real_UserName = RegisterData.UserName.replaceAll('-', '');
      const data_to_api = {
          event_id : id,
          user_name : real_UserName,
          user_organization_name : RegisterData.user_organization_name
      };
      dispatch(ApiExternalPreregister(data_to_api)).then(({ type,data_info }) => {
          if(type.endsWith('_SUCCESS')){
            if(data_info.code == 200){
              setSuccessMessageModal({
                  headline: 'ลงทะเบียนสำเร็จ',
                  subtitle: data_info.full_name,
                  description: 'ขอบคุณที่สนใจเข้าร่วมกิจกรรม',
              })
              setSuccessModal(true);
            }else if(data_info.code == 201){ //---- กรณียืนยัน Email
              if(RegisterData.user_organization_name){
                navigate('/EventSentEmail/' + id + '/' + real_UserName + '/' + RegisterData.user_organization_name)
              }else{
                navigate('/EventSentEmail/' + id + '/' + real_UserName + '/-')
              }

            }else{
              setFailMessageModal({
                headline: 'ขออภัยไม่สามารถลงทะเบียนได้',
                description: data_info.message,
              })
              setFailModal(true)
            }
          }else{
            setFailMessageModal({
              headline: 'internal server error',
              description: '',
            })
            setFailModal(true)
          }
      })
    }
  }

  const isValid = () => {
    let data_valid = RegisterData
    let data_check_valid = RegisterDataError
    let errors = []
    Object.keys(RegisterDataError).forEach((k) => {
      let data = data_valid[k]
      if (data_check_valid[k].errorMessage != '') {
        if (!data || data < 0) {
          errors.push(data_check_valid[k].errorMessage)
          data_check_valid[k].show_errorMessage = true
          if (k == 'UserName') {
            data_check_valid[k].errorMessage = 'กรุณากรอกเลขบัตรประจำตัวประชาชน'
          }
        } else {
          if (k == 'UserName') {
            const real_UserName = data.replaceAll('-', '').replaceAll('_', '');
            if(real_UserName.length != 13){
              errors.push(data_check_valid[k].errorMessage)
              data_check_valid[k].errorMessage = 'กรุณากรอกให้ครบ 13 หลัก'
              data_check_valid[k].show_errorMessage = true
            }else{
              data_check_valid[k].show_errorMessage = false
            }
          } else {
            data_check_valid[k].show_errorMessage = false
          }
        }
      }
    })
    setRegisterDataError({ ...RegisterDataError })
    return errors
  }

  const gotoPreregisterGeneral = () => {
    navigate('/EventPreregisterGeneral/' + id)
  }
  
  return (
    <Div>
        <div className="img-container">
          <img src={logoIcon} alt="loco icon" />
        </div>
        <div className="title">ลงทะเบียนเข้าร่วมกิจกรรมองค์กรแม่ข่าย</div>
        <div className="css_magin_top_32"></div>
        <div className="event_name">{DataInfo.event_name}</div>
        <div className="css_magin_top_12"></div>
        <div className="event_date">วันที่ {DateDisplayFullNameMonth(DataInfo.start_at)} เวลา {TimeDisplayHHmm(DataInfo.start_at)} น.</div>
        <div className="css_magin_top_12"></div>
        <div className="event_organizations_name">
          โดย {DataInfo.organizations_name}
        </div>
        <div className="css_magin_top_32"></div>
        <div>
            <Field className="field" label="เลขบัตรประจำตัวประชาชน">
                <DivStyleInputMask>
                  <InputMask 
                      placeholder="ระบุเลขบัตรประจำตัวประชาชน 13 หลัก"
                      mask="9-9999-99999-99-9" 
                      onChange={(e) => setRegisterData({ ...RegisterData, UserName: e.target.value })}
                      value={RegisterData.UserName} 
                  />
                </DivStyleInputMask>
                {RegisterDataError.UserName.show_errorMessage == true && (
                  <DivErrorMessage>{RegisterDataError.UserName.errorMessage}</DivErrorMessage>
                )}
            </Field>
        </div>
        {DataInfo?.is_open_general_user && (
          <div className="box_input_1">
              <div>
                  <Field className="field" label="ชื่อหน่วยงาน/ที่ทำงาน ของท่าน">
                      <TextField
                      placeholder="กรุณาระบุ"
                      value={RegisterData.user_organization_name}
                      onChange={(e) => setRegisterData({ ...RegisterData, user_organization_name: e.target.value })}
                      />
                  </Field>
              </div>
          </div>
        )}
        <div className="css_magin_top_32"></div>
        <Button
            className="button-login"
            type="submit"
            onClick={ApiProcess}
          >
            ลงทะเบียนเข้าร่วมกิจกรรม
          </Button>
          {DataInfo?.is_open_general_user && (
            <div>
              <div className="css_magin_top_32"></div>
              <div className="desc">
                ไม่ใช้สมาชิกสภาวิศวกร?
              </div>
              <div className="css_magin_top_32"></div>
              <ButtonOutline
                className="button-login"
                onClick={gotoPreregisterGeneral}
              >
                ลงทะเบียนสำหรับบุคคลทั่วไป
              </ButtonOutline>
            </div>
          )}
          <div className="css_magin_top_32"></div>
          <div className="desc">
            วัถตุประสงค์ลงทะเบียนเพื่อรับคะแนน CPD ทันทีเมื่อจบกิจกรรม
          </div>

          <DialogSuccess
            open={Boolean(successModal)}
            onClose={() => setSuccessModal(false)}
            onSubmit={() => setSuccessModal(false)}
            icon={successIcon}
            title={successMessageModal.headline}
            subtitle={successMessageModal.subtitle}
            description={successMessageModal.description}
            textYes={'ตกลง'}
        />
       <DialogFail
            open={Boolean(failModal)}
            onClose={() => setFailModal(false)}
            onSubmit={() => {
                setFailModal(false)
            }}
            icon={failIcon}
            title={failMessageModal.headline}
            description={failMessageModal.description}
            textYes='ตกลง'
        />
    </Div>
  )
}

export default EventPreregister
