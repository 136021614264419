
import { IoBookOutline } from "react-icons/io5";
import { TbUsersPlus } from "react-icons/tb";
import { LuTable2 } from "react-icons/lu";
import { BsPencil } from "react-icons/bs";
import { TbMessageQuestion } from "react-icons/tb";



export const subMenuHome = [
  {
    text: 'Home',
    path: '/',
    subject: null,
  },
]

export const subMenuCpdManage = [
  {
    icon: <TbUsersPlus />,
    text: 'ตรวจสอบคะแนน My CPD',
    path: '/Member/CpdPoint',
    subject: null, 
  },
  {
    icon: <IoBookOutline />,
    text: 'E-learning',
    path: '/Activity/Elearning',
    subject: null, 
  },
  {
    icon: <LuTable2 />,
    text: 'ตรวจสอบตารางกิจกรรม',
    path: '/Activity/Events',
    subject: null, 
  },
  {
    icon: <BsPencil />,
    text: 'บันทึกคะแนนกิจกรรม',
    path: '/Activity/SelfDeclaration',
    subject: null, 
  },
  {
    icon: <TbMessageQuestion />,
    text: 'คำร้องอุทธรณ์',
    path: '/Appeal/AppealInfo',
    subject: null, 
  },
]